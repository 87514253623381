<template>
  <div>
    <ch-layout page search >

      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>

<!--      <template #button>-->
<!--        <div class="flex">-->
<!--          <div class="btn active" >全部</div>-->
<!--          <div class="btn">待支付</div>-->
<!--        </div>-->
<!--      </template>-->

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:amount="scope">
            <span>
              {{scope.row.amount}}
              <el-tooltip class="item" effect="dark" content="保证好服务质量！" placement="top">
                <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" />
              </el-tooltip>
            </span>
          </template>
          <template v-slot:handle="scope">
            <ch-button type="link" @click="toPage(scope.row.id)">详情</ch-button>
<!--            <ch-button type="link" @click="openRefund(scope.row.goodsName,scope.row.amount,scope.row.id)"-->
<!--                       v-if="scope.row.status===2&&scope.row.useNumber===0">退款</ch-button>-->
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page" :sizeDe="20"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <Refund ref="refund"></Refund>
  </div>
</template>

<script>
import Refund from "./refund";
import {mapGetters,mapMutations} from "vuex";
export default {
  components: {Refund},
  data() {
    return {
      searchForm:{
        goodsName: "",
        orderNo: "",
        staffName: "",
        status: '',
        userName: "",
        userPhone: ""
      },
      tableList: [],
      isTableLoading: false,
      status:['待支付','已取消','待使用','已过期','已完成','已退款中','已退款'],
      active:0,
    }
  },
  computed: {
    searchOption: function () {
      return [
        {type: 'input', label: '订单编号：', prop: 'orderNo', placeholder: "请输入订单编号",},
        {type: 'input', label: '服务名称：', prop: 'goodsName', placeholder: "请输入服务名称", width: "140px"},
        {type: 'input', label: '平台活动：', prop: 'activityName', placeholder: "请输入平台活动名称", width: "150px"},
        {
          type: "select", label: "状态", prop: "status", placeholder: "请选择状态", labelWidth: "40px",
          name: "label", value: "value", option: [
            {value: 0, label: "待支付"},
            {value: 1, label: "已取消"},
            {value: 2, label: "待使用"},
            {value: 3, label: "已过期"},
            {value: 4, label: "已完成"},
            {value: 5, label: "已退款中"},
            {value: 6, label: "已退款"},
          ]
        },
        {
          type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]
        },

      ]
    },
    tableOption: function () {
      return [
        {column: "type", prop: "", type: "selection", width: "25px"},
        {column: "type", label: "序号", prop: "", type: "index", width: "70",},
        {column: "text", label: "订单编号", prop: "orderNo", showOverflowTooltip: true,},
        {column: "text", label: "服务名称", prop: "goodsName", showOverflowTooltip: true,},
        {column: "slot", label: "实际价格", slotName: "amount", showOverflowTooltip: true,},
        {column: "text", label: "下单时间", prop: "createTime", showOverflowTooltip: true,},
        {column: "text", label: "使用次数", prop: "_useNumber", showOverflowTooltip: true,},
        {column: "text", label: "平台活动", prop: "activityName", showOverflowTooltip: true,},
        {column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        {column: "slot", label: "操作", slotName: "handle", width: "100"},
      ];
    },
    ...mapGetters(['flush'])
  },
  mounted() {
    this.getTableList()
  },
  watch: {
    flush: function (val) {
      if(val) {
        this.getTableList()
        this.saveFlush(false)
      }
    }
  },
  methods: {
    ...mapMutations(['saveFlush']),
    // 查询
    searchHandle() {
      this.getTableList()
    },
    getTableList(params=this.searchForm) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/order/list",params).then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          return {
            ...item,
            _useNumber: item.useNumber +'/'+ item.number,
            _status: this.status[item.status],
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    // 跳转详情
    toPage(orderId){
      this.$router.push({ name: "orderDetail", query: { orderId } });
    },
    // 退款
    openRefund(goodsName,amount,id){
      let data = {
        goodsName,amount,id
      }
      this.$refs.refund.openRefund(data)
    }
  }
}

</script>

<style scoped lang="scss">
.btn{
  box-sizing: border-box;
  padding: 7px 15px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  border-radius: 4px;
  color: #3e7eff;
  border: 1px solid #3e7eff;
  background-color: #ecf5ff;
  margin-right: 10px;
}
.active{
  color: #fff;
  background: #3e7eff;
  border: 1px solid #3e7eff;
}
</style>